export const authedFetch: typeof fetch = (input, init) =>
  fetch(input, {
    credentials: 'include',
    ...init,
  }).then(response => {
    switch (response.status) {
      case 401:
      case 403: {
        window.document.cookie = '';
        return fetch(`${process.env.NEXT_PUBLIC_API_URL}/sign_out`, {
          credentials: 'include',
          method: 'post',
        }).then(() => {
          return Promise.reject();
        });
      }
      default:
        return response;
    }
  });
