import type { AppProps } from 'next/app';
import Head from 'next/head';
import { SWRConfig } from 'swr';

import '@/styles/globals.css';
import { authedFetch } from '@/components/authedFetch';
import { useAppHeight } from '@/components/useAppHeight';

const fetcher = async (url: string) => {
  const res = await authedFetch(url, {
    credentials: 'include',
    headers: { accept: 'application/json' },
  });

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.', {
      // Attach extra info to the error object.
      cause: { info: await res.json(), status: res.status },
    } as any);

    console.error('fetch error ', error.cause);

    throw error;
  }

  return res.json();
};
const swrConfig = { fetcher };

const description =
  'Quantsy leverages an advanced machine learning algorithm to valuate NFTs.';

function MyApp({ Component, pageProps }: AppProps) {
  useAppHeight();

  return (
    <SWRConfig value={swrConfig}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <title>Quantsy</title>
        <meta name="description" content={description} key="description" />
        <meta
          property="og:description"
          content={description}
          key="og:description"
        />
        <meta property="og:type" content="website" key="og:type" />
        <meta property="og:site_name" content="Quantsy" key="og:site_name" />
        <meta property="og:locale" content="en_US" key="og:locale" />
        <meta
          property="og:image"
          content="https://dashboard.quantsy.xyz/share.jpeg"
          key="og:image"
        />
        <meta property="og:image:alt" content="Quantsy" key="og:image:alt" />
        <meta
          property="og:url"
          content="https://dashboard.quantsy.xyz/"
          key="og:url"
        />
      </Head>
      <Component {...pageProps} />
    </SWRConfig>
  );
}

export default MyApp;
